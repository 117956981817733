import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["numerical", "text", "presence"]
  connect(){
  }

  onSave(event) {
    // find modals only for location modal in weekly schedule
    let modals = document.querySelectorAll('.overlay')

    // for each inputfield that should be present, check if it is present.
    for (let i = 0; i < this.presenceTargets.length; i++) {
      let inputField = this.presenceTargets[i]
      if (!inputField.value) { // if not present
        if (modals.length > 0) {
          modals[i].classList.remove('hidden')
        }
        if (!inputField.nextSibling || inputField.nextSibling.getAttribute('id') != 'errormsg') {
          this.highlightError(inputField)
        }
      }
    }
  }

  onInput(event) {
    let inputField = event.target
    if (inputField.value && inputField.nextSibling) {
      this.removeError(inputField)
    } else {
      if (!inputField.nextSibling || inputField.nextSibling.getAttribute('id') != 'errormsg') {
        this.highlightError(inputField)
      }
    }
  }

  highlightError(inputField) {
    let fieldName = inputField.getAttribute("data-field-name")
    // highlight inputField in red
    inputField.classList.remove('border-eerie-black-20')
    inputField.classList.add('border-red-100')
    // append error message to inputField as caption
    const newDiv = document.createElement('div')
    const errorMsg = document.createTextNode(`${fieldName} is required.`) // presence error
    newDiv.appendChild(errorMsg);
    newDiv.classList.add('caption-2', 'text-red-100', 'justify-self-end')
    newDiv.setAttribute('id', 'errormsg')
    inputField.parentElement.appendChild(newDiv)
  }

  removeError (inputField) {
    if (inputField.nextSibling.getAttribute('id') === 'errormsg') {
      inputField.nextSibling.remove();
      inputField.classList.remove('border-red-100')
      inputField.classList.add('border-eerie-black-20')
    }
  }

  disableButton(event) {
    event.target.classList.add("btn-cq2-disabled", "pointer-events-none");
    event.target.classList.remove("border-yellow-400", "btn-cq2", "button-3");
  }
}

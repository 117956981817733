import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["course", "timeslot", "counter"]
  // --------------------------------- Registered page -----------------------------------------------------
  // js interaction for the tabs in attendance sheet
  active_tab(event){
    let tabsContainer = document.querySelector("#tabs");
    // Get all the tab toggler from the container
    let tabTogglers = tabsContainer.querySelectorAll("a");
    // Remove all tabs's border & opacity classes
    tabTogglers.forEach(function(toggler) {
      toggler.parentElement.classList.remove("border-coquelicot-100", "border-b-2", "opacity-100");
    })
    // Add the border & opacity classes in the active state event
    event.target.parentElement.classList.add("border-coquelicot-100", "border-b-2", "opacity-100");
  }

  mark_attendance(event) {
    event.preventDefault();
    let clicked = this.element.querySelector("a");
    // Disable icon to prevent multiple AJAX calls
    clicked.style.pointerEvents = 'none';
    Rails.ajax({
      url: '/manage/courses/'+ event.target.getAttribute('data-course-id') + '/timeslots/' + event.target.getAttribute('data-timeslot-id') + '/attendances',
      type: 'POST',
      // Rails-ujs doesn't accept JSON as data arguments
      data: "enrolment_id=" + event.target.getAttribute('data-enrolment-id') + "&attendanceStatus=" + event.target.getAttribute('data-attendance-status') + "&date=" + event.target.getAttribute("data-date"),
      success: function(data) {
        // Enable icon after AJAX success
        clicked.style.pointerEvents = "cursor";
        let attendanceOptions = document.getElementById("mark-attendance-" + event.target.getAttribute('data-enrolment-id'))
        let attendanceStatusChange= document.getElementById("attendance-status-" + event.target.getAttribute('data-enrolment-id'))
        // This will return the circle icon
        let icon = attendanceStatusChange.children[0]
        // This will return the text status
        let text = attendanceStatusChange.children[1]

        attendanceOptions.classList.add("hidden")
        attendanceStatusChange.classList.remove("hidden")

        if(data.status == "true"){
          icon.classList.add("text-emerald-100", "marked")
          text.innerHTML = "Attended"
        } else {
          icon.classList.add("text-coquelicot-100")
          text.innerHTML = "Missed"
        }

        // decide whether to update trigger payment class counter
        let all_unmarked_attendances = Array.from(document.querySelectorAll("[id^='attendance-status-']"));
        // count the number of attendances marked attended ("marked" was added to classList)
        let count = all_unmarked_attendances.map(att => att.innerHTML.includes("marked")).filter(Boolean).length;
        // if it is the first positive attendance being marked at page load && is the first positive attendance being marked
        if ((event.target.getAttribute("data-attendance-new") === "true") && (count < 2)) {
          // Lessons held till next payment: {counter}/{num_private_class_charged}
          let counter = document.getElementById("trigger-payment-counter");
          if (counter) {
            let num_private_class_charged = document.getElementById("num_private_class_charged").innerHTML;
            // increase lessons_held_count by 1
            let lessons_held_count = parseInt(counter.innerHTML) + 1;
            counter.innerHTML = `${lessons_held_count}`;
            // enable trigger payment button if eligible
            if (lessons_held_count >= parseInt(num_private_class_charged)) {
              let btn = document.getElementById("trigger-payment-btn").children[0];
              btn.classList.add("btn-cq2", "button-3", "border-yellow-400");
              btn.classList.remove("btn-cq2-disabled", "inline");
            };
          } else {
            let groupTriggerButton = document.getElementById("group-trigger-payment-btn");
            let lessonAttendedArr = JSON.parse(groupTriggerButton.dataset.lessonAttended);
            // Check that some enrolment's attendance has been fully attended. For eg, 4/4. (e + 1) because the value of lesson attended doesn't instantly update when marked attendance. Hence, we have to treat it as the user has attended a lesson (prev lesson count + current lesson)
            if (JSON.parse(groupTriggerButton.dataset.lessonAttended).some((e) => (e + 1) % groupTriggerButton.dataset.numOfClassCharged == 0)){
              groupTriggerButton.children[0].classList.add("btn-cq2", "button-3", "border-yellow-400");
              groupTriggerButton.children[0].classList.remove("btn-cq2-disabled", "inline");
            } else {
              groupTriggerButton.children[0].classList.remove("btn-cq2", "button-3", "border-yellow-400");
              groupTriggerButton.children[0].classList.add("btn-cq2-disabled", "inline");
            }
          }
        };
      }
    });
  }

  connect() {
    // on load, if the trigger payment button is being loaded
    if (this.hasCounterTarget) {
      // Lessons held till next payment: {counter}/{num_private_class_charged}
      let counter = this.counterTarget.innerHTML
      let num_private_class_charged = document.getElementById("num_private_class_charged").innerHTML
      // -------------------- check if btn should be disabled --------------------------------------
      if (parseInt(counter) < parseInt(num_private_class_charged)) {
        // Logic for private class attendance based trigger payment button
        let btn = document.getElementById("trigger-payment-btn").children[0];
        btn.classList.remove("btn-cq2", "button-3", "border-yellow-400");
        btn.classList.add("btn-cq2-disabled", "inline");
      }
    } else {
      // Logic for group class attendance based trigger payment button
      let groupTriggerButton = document.getElementById("group-trigger-payment-btn");
      if (groupTriggerButton.dataset.anyCheckStudentFullyAttended == "false") {
        groupTriggerButton.children[0].classList.add("btn-cq2-disabled", "inline");
        groupTriggerButton.children[0].classList.remove("btn-cq2", "button-3", "border-yellow-400");
      } else {
        groupTriggerButton.children[0].classList.remove("btn-cq2-disabled", "inline");
        groupTriggerButton.children[0].classList.add("btn-cq2", "button-3", "border-yellow-400");
      }
    }
  }

  // --------------------------------- Waiting List page --------------------------------------------------
  // js interaction when clicked "Move student" button. Used in waiting_list.html.slim
  // Also used for "Shift Schedule" button. Used in registered.html.slim
  move_students(event){
    event.preventDefault();
    let allCheckboxes = document.querySelectorAll('.attendance_checkbox');
    let attendanceCheckboxHeader = document.getElementById('attendance_checkbox_header');
    let banner = document.getElementById("move-student-banner");
    let moveAsRegisteredAlert = document.getElementById('move_as_registered_alert');
    let moveStudentsBtn = document.getElementById('move_students_btn');
    // show the checkboxes and alert, and hide the button
    allCheckboxes.forEach(function(checkbox) {
      checkbox.classList.remove('hidden');
    })
    attendanceCheckboxHeader.classList.remove("hidden");
    moveStudentsBtn.classList.add('hidden');
    moveAsRegisteredAlert.classList.remove('hidden');
    banner.classList.remove("hidden")
  }

  change_students_count(event) {
    let allCheckboxes = document.querySelectorAll('.attendance_checkbox');
    let bannerText = document.getElementById("students-count");
    let count = 0;
    // count total number of checked checkboxes
    for (let i = 0; i < allCheckboxes.length; i++) {
      if (allCheckboxes[i].firstChild.checked === true) {
        count++;
      }
      // Print banner text with dynamic count
      bannerText.innerHTML = count + " students are selected";
    }
  }

  close_banner(event) {
    event.preventDefault();
    let allCheckboxes = document.querySelectorAll('.attendance_checkbox');
    let attendanceCheckboxHeader = document.getElementById('attendance_checkbox_header');
    let banner = document.getElementById("move-student-banner");
    let moveAsRegisteredAlert = document.getElementById('move_as_registered_alert');
    let moveStudentsBtn = document.getElementById('move_students_btn');
    // hide all the checkboxes and alert, and hide the button
    allCheckboxes.forEach(function(checkbox) {
      checkbox.classList.add('hidden');
    })
    attendanceCheckboxHeader.classList.add("hidden");
    moveStudentsBtn.classList.remove('hidden');
    moveAsRegisteredAlert.classList.add('hidden');
    banner.classList.add("hidden")
  }
}

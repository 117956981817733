import { Controller } from "stimulus"

export default class extends Controller {
  // --------------------------------- Lesson plan classes page -----------------------------------------------------
  // js interaction for the tabs in testimonial sheet
  active_tab(event){
    let tabsContainer = document.querySelector("#tabs");
    // Get all the tab toggler from the container
    let tabTogglers = tabsContainer.querySelectorAll("a");
    // Remove all tabs's border & opacity classes
    tabTogglers.forEach(function(toggler) {
      toggler.parentElement.classList.remove("border-coquelicot-100", "border-b-2", "opacity-100");
    })
    // Add the border & opacity classes in the active state event
    event.target.parentElement.classList.add("border-coquelicot-100", "border-b-2", "opacity-100");
  }
}

import consumer from "./consumer"
document.addEventListener("turbo:load", () =>  {
  const chatBody = document.querySelector(".chat-body");
  chatBody.scrollTop = chatBody.scrollHeight;
  let currentAccount = document.querySelector(".chat-body").getAttribute('data-current-id');
  if (chatBody.getAttribute('data-school-admin-id') != 0) {
    // if it's not zero it's a school admin
    currentAccount = chatBody.getAttribute('data-school-admin-id');
  }
  //  the other account you are chatting with
  const otherAccount = document.querySelector(".chat-body").getAttribute('data-other-id');
  // pair id combines your id and other account id; smaller id will always be in front of the larger id
  let pairId = "";
  if (parseInt(currentAccount) < parseInt(otherAccount)) {
    pairId += currentAccount + "_" + otherAccount;
  } else {
    pairId += otherAccount + "_" + currentAccount;
  }
  consumer.subscriptions.create({channel: "ChatChannel", pair: pairId}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
    received(data) {
      if (data.content) {
        // const chatBody = document.querySelector(".scroll.py-4.chat-height");
        if (currentAccount == data.sender_id) {
           let content = '<div class="w-full grid grid-cols-10 relative"><div class="sender"><div class="p3">' +data.content + '</div><div class="caption-3 mt-1">' + data.datetime + 
          '</div></div></div>';
          chatBody.innerHTML += content;
        } else {
          let content = '<div class="w-full grid grid-cols-10 relative"><div class="recipient"><div class="p3">' + data.content + '</div><div class="caption-3 mt-1">' + data.datetime + 
          '</div></div></div>';
          chatBody.innerHTML += content;
        }
        // with hover action
        // let content = '<div class="w-full grid grid-cols-10 relative"><div class="sender"><div class="p3">' + data.username + ':' + data.content + '</div><div class="caption-3 mt-1">' + data.datetime + 
        // '</div></div><div class="hover-action-sender hidden flex items-center"><div class="material-icons-outlined text-eerie-black-60">reply</div><div class="material-icons-outlined text-eerie-black-60 ml-1">star_outline</div><div class="material-icons-outlined text-eerie-black-60 ml-1">more_horiz</div></div></div>'
        
        // to remove what's in the text box after you send a message
        document.getElementById("message_content").value = null;
        // scroll to bottom after receiving a message
        chatBody.scrollTop = chatBody.scrollHeight;
      }   
      // Called when there's incoming data on the websocket for this channel
    }
  });

  // to broadcast updates for new messages in the chat sidebar
  document.querySelectorAll(`[data-sidebar-id]`).forEach((sidebar) => {
    const otherSidebarId = sidebar.getAttribute('data-sidebar-id');
    const sidebarPairId = (parseInt(currentAccount) < parseInt(otherSidebarId)) ? (currentAccount + "_" + otherSidebarId) : (otherSidebarId + "_" + currentAccount);
    let unreadChannel = consumer.subscriptions.create({channel: "UnreadChannel", pair: sidebarPairId}, {
      connected() {
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received(data) {
        // check if the sidebar chat tab contains active class, if yes just replace the message
        let sidebarChat = document.querySelector(`[data-sidebar-id='${otherSidebarId}']`);
        let lastMessage = sidebarChat.getElementsByClassName("last-message")[0];
        lastMessage.innerHTML = data.content;
        let lastMessageTime = sidebarChat.getElementsByClassName("last-message-time")[0];
        lastMessageTime.innerHTML = data.datetime;
        // Called when there's incoming data on the websocket for this channel
      }
    });
  });
})

import { Controller } from "stimulus"
const lessons = {
  students: 0,
  left: 0,
  price: 0,
  deposit: 0,
  oneTimeFee: 0
};
// Defined key values:
// lessons.price: amount per lesson for 1 student
// lessons.left: number of lessons left in a month (from form)
// lessons.students: number of students (from form)
// lessons.deposit: amount of full one month fee for deposit (optional)
// lessons.oneTimeFee: amount of one time fee (optional)

export default class extends Controller {
  static targets = ["lessons", "students", "totals"]

  connect() {
    let deposit = document.getElementById("deposit")
    if(deposit){
      lessons.deposit = deposit.dataset.deposit
    }
    let oneTimeFee = document.getElementById("one-time-fee")
    if(oneTimeFee){
      lessons.oneTimeFee = oneTimeFee.dataset.feeAmount
    }
  }

  getProrateDates(event){
    let lessonArray = event.target.dataset.lessons
    let unitPriceArray = event.target.dataset.unitprice
    let index = event.target.selectedIndex - 1
    lessonArray = (lessonArray.replace(/[\[\]']+/g,'').split(','))
    unitPriceArray = (unitPriceArray.replace(/[\[\]']+/g,'').split(','))
    lessons.left = lessonArray[index] || 0
    lessons.price = unitPriceArray[index] || 0
    this.calculatePrice()
 }

  numberOfStudents(event){
    lessons.students = event.target.value || 0
    this.calculatePrice()
  }

 calculatePrice(){
  document.getElementById("lessons-left").innerHTML = lessons.left + " "
  document.getElementById("num-of-students").innerHTML = lessons.students + " "
  let finalTotal = ((parseInt(lessons.price) * parseInt(lessons.left) / 100 ) + parseInt(lessons.deposit) + parseInt(lessons.oneTimeFee)) * parseInt(lessons.students)
  document.querySelector("#total-amount").innerHTML = " Total: $" + finalTotal
  }
}

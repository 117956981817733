import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["course", "timeslot", "counter"]
  // --------------------------------- Registered page -----------------------------------------------------
  // js interaction for the tabs in timeslots SHOW page (Learners account)
  active_tab(event){
    let tabsContainer = document.querySelector("#student_profile_tabs");
    // Get all the tab toggler from the container
    let tabTogglers = tabsContainer.querySelectorAll("a");
    // Remove all tabs's border & opacity classes
    tabTogglers.forEach(function(toggler) {
      toggler.classList.remove("border-coquelicot-60", "bg-coquelicot-10");
    })
    // Add the border & opacity classes in the active state event
    event.target.classList.add("border-coquelicot-60", "bg-coquelicot-10");
  }

  connect(){
    // select the first student by default on page load
    let tabsContainer = document.querySelector("#student_profile_tabs");
    if (tabsContainer){
      let firstToggler = tabsContainer.querySelector("a");
      firstToggler.classList.add("border-coquelicot-60", "bg-coquelicot-10");
    }
  }
  update_published(e){
    let clicked = this.element.querySelector(".published");
    Rails.ajax({
      url:'/manage/courses/'+clicked.dataset.courseId+ '/update_published_status',
      type:'PUT',
      data: "published=" + e.target.selectedOptions[0].value
    })

  }

  search_course() {
    let category = document.getElementById("course_category").selectedOptions[0].value;
    let location = document.getElementById("course_location").selectedOptions[0].text;
    let day = document.getElementById("course_day").selectedOptions[0].text;
    let time = document.getElementById("course_time").selectedOptions[0].text;
    if (category == "Any subject") {
      category = "";
    } else if (category.includes("Others")) {
    } else {
      category = 'Course[refinementList][conditioned_category][0]=' + category;
    }
    if (location == "Central") {
      location = 'Course[refinementList][locations.region][0]=Central 1 (Marine Parade, Geylang)&Course[refinementList][locations.region][1]=Central 2 (Kallang, Bendemeer, Rochor)&Course[refinementList][locations.region][2]=Central 3 (Toa Payoh, Bishan)&Course[refinementList][locations.region][3]=Central 4 (Novena, Balestier, Newton, River Valley)&Course[refinementList][locations.region][4]=Central 5 (Outram, Singapore River, Downtown)&Course[refinementList][locations.region][5]=Central 6 (Bukit Merah, Tiong Bahru, Queenstown, Commonwealth)&Course[refinementList][locations.region][6]=Central 7 (Tanglin, Stevens, Orchard)&Course[refinementList][locations.region][7]=Central 8 (Novena, Dunearn)&Course[refinementList][locations.region][8]=Central 9 (Bukit Timah, Dunearn)'
    } else if (location == "North") {
      location = 'Course[refinementList][locations.region][0]=North 1 (Admiralty, Woodlands)&Course[refinementList][locations.region][1]=North 2 (Sembawang, Yishun)&Course[refinementList][locations.region][2]=North 3 (Kranji, Sungei Kadut)&Course[refinementList][locations.region][3]=North 4 (Lim Chu Kang, Yew Tee)';
    } else if (location == "North East") {
      location = 'Course[refinementList][locations.region][0]=North East 1 (Punggol, Seletar)&Course[refinementList][locations.region][1]=North East 2 (Sengkang, Buangkok)&Course[refinementList][locations.region][2]=North East 3 (Hougang, Serangoon)&Course[refinementList][locations.region][3]=North East 4 (Ang Mo Kio, Thomson)';
    } else if (location == "East") {
      location = 'Course[refinementList][locations.region][0]=East 1 (Pasir Ris, Changi)&Course[refinementList][locations.region][1]=East 2 (Tampines, Simei)&Course[refinementList][locations.region][2]=East 3 (Bedok, Kembangan)&Course[refinementList][locations.region][3]=East 4 (Bayshore, Siglap)';
    } else if (location == "West") {
      location = 'Course[refinementList][locations.region][0]=West 1 (Clementi, West Coast)&Course[refinementList][locations.region][1]=West 2 (Jurong East, Pandan, Jurong Lake)&Course[refinementList][locations.region][2]=West 3 (Jurong West, Boon Lay, Pioneer)&Course[refinementList][locations.region][3]=West 4 (Bukit Batok, Bukit Gombak)&Course[refinementList][locations.region][4]=West 5 (Chua Chu Kang, Tengah)&Course[refinementList][locations.region][5]=West 6 (Bukit Panjang)';
    } else {
      location = "";
    }
    if (day == "Weekday") {
      day = 'Course[refinementList][timeslots.day_of_week][0]=Monday&Course[refinementList][timeslots.day_of_week][1]=Tuesday&Course[refinementList][timeslots.day_of_week][2]=Wednesday&Course[refinementList][timeslots.day_of_week][3]=Thursday&Course[refinementList][timeslots.day_of_week][4]=Friday';
    } else if (day == "Weekend") {
      day = 'Course[refinementList][timeslots.day_of_week][0]=Saturday&Course[refinementList][timeslots.day_of_week][1]=Sunday';
    } else {
      day = "";
    }
    if (time == "Any time") {
      time = "";
    } else {
      time = 'Course[refinementList][timing.time_of_day][0]=' + time;
    }
    let query = [category, location, day, time].filter(e =>  e)
    window.location.href = '/marketplace/courses?'  + query.join("&");
    return false;
  }

  toggle(e) {
    let results = document.getElementById("hits_course");
    let btn = e.target;
    if (results.classList.contains("hidden")) {
      results.classList.remove("hidden");
      btn.innerHTML = "Map";
    } else {
      results.classList.add("hidden");
      btn.innerHTML = "List";
    }
  }
}

import { Controller } from "stimulus"
import { Calendar } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import rrulePlugin from "@fullcalendar/rrule"
import Rails from "@rails/ujs"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default class extends Controller {
  static targets = [ "calendar" ]

  connect() {

    let _this = this
    // Render a monthly, weekly, daily calendar view
    let calendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin],
      timeZone: 'SGT',
      // Pass course's timeslot events to display
      events: JSON.parse(_this.element.getAttribute("data-events")),
      initialView: 'timeGridWeek',// set default weekly view
      editable: false, //draggable event
      navLinks: true,
      selectable: true,
      selectHelper: true,
      interactive: true,
      allowHTML: true, //to render string as HTML
      dayMaxEventRows: true, // for all non-TimeGrid views


      eventDidMount: function(info) {
        //Shows class name and schedule of lesson
        let student_attending = '<div class="p-3"><div class="p4 mb-2 flex items-center"><div class="ml-1"><div class="subtitle-3 mb-2">' + info.event.extendedProps.course_name + '</div><div class="p3 mb-2">' + info.event.extendedProps.course_start_time + '</div><div class="p3 mb-2">' + info.event.extendedProps.class_location + '</div><div class="p3 mb-2">Taught by ' + info.event.extendedProps.teacher_name + '</div></div></div><div class="testimonial mb-4 block overflow-auto bg-platinum-30">';
        //Students that are registered to the class
        let students_displayed = info.event.extendedProps.student_profiles_teacher
        if (info.event.extendedProps.is_parent) {
          students_displayed = info.event.extendedProps.student_profiles_learner
        }
        for (let i=0; i < students_displayed.length; i++) {
          let src = students_displayed[i][1]
          if (src == null) {
            src = info.event.extendedProps.default_avatar
          }
          student_attending += '<div class="flex items-center mb-2"><img src=' + src + ' class="small-circle"><div class="ml-2"><div class="p4 text-left">' + students_displayed[i][0] + '</div></div></div>'
        };
        //Direct to attendance list page for registered student, don't show if the current account has a parent_student role
        if (!info.event.extendedProps.is_parent) {
          student_attending += '</div><a href="/manage/courses/' + info.event.extendedProps.course_id + '/timeslots/' + info.event.extendedProps.timeslot_id + '/attendances/new"><div class="button-4 rounded flex items-right justify-end">View attendance list</div></a></div>'
        }
        document.querySelector('.fc-event-main:not([id])').setAttribute('id', info.event.extendedProps.timeslot_id);
        tippy(`[id='${info.event.extendedProps.timeslot_id}']`, {
          placement: 'right', //display popover on the right side of timeslot
          arrow: true,
          trigger: 'click',
          interactive: true, //allows user to scroll and click in the popover
          animateFill: false,
          animation: 'fade',
          flipOnUpdate: true,
          allowHTML: true, //allows HTML to be written
          appendTo: document.body,
          content: student_attending,
        });
      },
    });
    calendar.render()
  }
}

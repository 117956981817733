import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closeBtn", "openBtn"];
  connect(){
    // let temp = this.closeBtnTargets
  }
  open(event){
    event.target.closest(".table").nextElementSibling.classList.remove("hidden")
  }

  close(event){
    event.target.closest(".modal-container").classList.add("hidden")
  }
}
